import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { LoginInfo } from 'projects/pw-lib/src/public-api';
import { AuthService } from 'src/app/auth/auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { UiService } from '../../core/services/ui.service';
import { MenuService } from '../../services/menu.service';
import { DialogService } from '../dialog/dialog.service';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-kumahanin-layout',
  templateUrl: './kumahanin-layout.component.html',
  styleUrls: ['./kumahanin-layout.component.scss'],
})
export class KumahaninLayoutComponent implements OnInit {
  @ViewChild('sideNav') sideNavContainer: MatSidenavContainer;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  loginInfo: LoginInfo;

  constructor(
    public uiService: UiService,
    public menu: MenuService,
    private authService: AuthService,
    private languageService: LanguageService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.authService.loginSubject$.subscribe((info) => {
      this.loginInfo = info;
    });
  }

  ngAfterViewInit(): void {
    this.uiService.openDrawerEmitter.subscribe(() => {
      this.sideNavContainer.open();
    });
  }

  closeAll(): void {
    this.sideNavContainer.close();
    this.accordion.closeAll();
  }

  onToggleLangClick(): void {
    if (this.languageService.lang === 'ko') {
      this.languageService.changeLang('ja');
    } else {
      this.languageService.changeLang('ko');
    }

    this.sideNavContainer?.close();
  }

  onLoginClick(): void {
    this.dialogService.matDialog.open(LoginComponent, {
      disableClose: true,
    });
  }

  onRegisterClick(): void {
    this.dialogService.matDialog.open(RegisterComponent, {
      disableClose: true,
      width: '100%',
    });
  }

  onLogoutClick(): void {
    this.loginInfo = null;
    this.authService.logout();
  }
}
