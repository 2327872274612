<div class="header-wrapper" fxLayout="column" ngClass.lt-sm="small">
  <div
    class="header-logo"
    fxLayout
    fxLayoutAlign="center center"
    ngClass.lt-sm="small"
  >
    <mat-icon
      fxShow.lt-sm
      fxHide
      *ngIf="showBack"
      fontSet="material-symbols-outlined"
      class="left"
      (click)="onBackClick()"
      >keyboard_arrow_left</mat-icon
    >
    <div class="tmp-title" routerLink="/home">
      {{ '쿠마모토 한인교류회' | translate }}
    </div>
    <div class="user-menu-container">
      <ng-container *ngIf="!loginInfo">
        <a class="user-menu" (click)="onLoginClick()">{{
          'login' | translate
        }}</a>
        |
        <a class="user-menu" (click)="onRegisterClick()">{{
          'register' | translate
        }}</a>
      </ng-container>
      <ng-container *ngIf="loginInfo">
        <a class="user-menu">{{ loginInfo.userNm || loginInfo.email }}</a>
        |
        <a class="user-menu" (click)="onLogoutClick()">{{
          'logout' | translate
        }}</a>
      </ng-container>
      |
      <a class="user-menu" routerLink="/my">{{ 'MY' | translate }}</a>
      <button class="user-menu-button" (click)="onToggleLangClick()">
        {{ 'toggleLang' | translate }}
      </button>
    </div>
    <!-- <a routerLink="/home">
    </a> -->
    <mat-icon
      fxShow.lt-sm
      fxHide
      fontSet="material-symbols-outlined"
      class="right"
      (click)="uiService.openDrawer()"
      >menu</mat-icon
    >
  </div>
  <div
    class="header-menu"
    fxHide.lt-sm
    [ngClass]="{ hover: showMenu }"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <ul fxLayout fxLayoutAlign="space-between">
      <ng-container *ngFor="let item of menu.MENU_LIST">
        <li class="menu" [class]="item.class" *ngIf="!item.hide">
          <a [routerLink]="item.basePath">
            {{ item.name | translate }}
          </a>

          <menu class="inner-menu" *ngIf="item.subMenu.length">
            <li *ngFor="let subMenu of item.subMenu">
              <a [routerLink]="subMenu.path">{{ subMenu.name | translate }}</a>
            </li>
          </menu>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<!-- <div class="header-spacer" ngClass.lt-sm="small"></div> -->
