import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  finalize,
  map,
  mergeMap,
  retryWhen,
  tap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IHalPageResponse, IPage } from './page.model';

export class PageRepositoryService<T> {
  /**
   * 서버 URL
   * @example `environment.serverUrl`
   */
  protected apiServerUrl = environment.apiServerUrl;

  /**
   * 엔드포인트
   * @example 'oauth/token'
   */
  protected baseUri = '';

  /** 통신 상태 관리 */
  isLoading = false;

  /** 통신 상태 관리 subject */
  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    this.isLoading
  );

  /** 통신 상태 */
  get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  // 조회 상태 유지 위한 변수
  private list!: IPage<T>;

  private options: Array<T> = null;

  private listSubject: BehaviorSubject<IPage<T>> = new BehaviorSubject(
    this.list
  );

  private optionsSubject: BehaviorSubject<Array<T>> = new BehaviorSubject(
    this.options
  );

  /**
   * 현재 검색조건
   *
   * @deprecated 용도에 따라 명칭을 명확하게 변경
   */
  searchQuery: any = {};

  /**
   * 현재 검색조건
   */
  recentSearchQuery: any = {};

  /**
   * 현재 조건 해당 목록
   *
   * getPage(단 건), appendPage(누적) 결과
   */
  get list$(): Observable<IPage<T>> {
    return this.listSubject.asObservable();
  }

  /**
   * 옵션 선택을 위한 전체 목록
   *
   * TODO: 최적화 필요
   */
  get options$(): Observable<Array<T>> {
    return this.optionsSubject.asObservable();
  }

  // /조회 상태 유지 위한 변수

  constructor(protected http: HttpClient) {
    this.initList();
  }

  create(data: T): Observable<T> {
    this.isLoadingSubject.next(true);
    return this.http.post<T>(`${this.apiServerUrl}/${this.baseUri}`, data).pipe(
      tap(() => {
        this.isLoadingSubject.next(false);
      }),
      this.handleError()
    );
  }

  findPage(params: any = {}, body: any = {}): Observable<IPage<T>> {
    return this.http
      .get<IHalPageResponse>(`${this.apiServerUrl}/${this.baseUri}`, {
        params,
      })
      .pipe(
        map((res) => this.parsePage(res)),
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  findItem(id: number): Observable<T> {
    this.isLoadingSubject.next(true);
    return this.http.get<T>(`${this.apiServerUrl}/${this.baseUri}/${id}`).pipe(
      tap(() => {
        this.isLoadingSubject.next(false);
      }),
      this.handleError()
    );
  }

  findItemByUsername(username: string): Observable<T> {
    this.isLoadingSubject.next(true);
    return this.http
      .get<IHalPageResponse>(`${this.apiServerUrl}/${this.baseUri}`, {
        params: { username },
      })
      .pipe(
        map((response) => {
          const { content } = this.parsePage(response);
          return content[0];
        }),
        finalize(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  update(id: any, item: T): Observable<T> {
    this.isLoadingSubject.next(true);
    return this.http
      .put<T>(`${this.apiServerUrl}/${this.baseUri}/${id}`, item)
      .pipe(
        finalize(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  delete(id: number): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.delete(`${this.apiServerUrl}/${this.baseUri}/${id}`).pipe(
      tap(() => {
        this.isLoadingSubject.next(false);
      }),
      this.handleError()
    );
  }

  /**
   * 검색 조건 변경.
   *
   * 새 조건에 맞춰 목록(새 페이지와 리스트 첫페이지) 갱신 필요
   *
   * @deprecated getPage, appendPage 로 검색 조건 포함하게 하고, 최근 기록이 네트워크 요청 후 설정되게 변경
   */
  setSearchQuery(query = {}): void {
    Object.assign(this.searchQuery, query);

    // 목록 초기화 후 첫 페이지 받기
    this.initList();

    this.findPage(query)
      .pipe(
        tap((res) => {
          this.list = res;

          this.listSubject.next(this.list);
        })
      )
      .subscribe();
  }

  /**
   * 페이지를 요청하고, 현재 목록에 페이지를 추가 한다.
   *
   * TODO: 전페이지 조회, 현재 페이지 조회 중간에 추가된 아이템이 있다면 중복 처리 해야 함
   */
  appendPage(query = this.recentSearchQuery): void {
    this.isLoadingSubject.next(true);

    this.findPage(query)
      .pipe(
        tap((res) => {
          // 성공했다면 최근 조건으로 set
          this.recentSearchQuery = query;

          // TODO: 중복 처리 필요
          this.list.content.push(...res.content);
          this.list.page = res.page;

          this.listSubject.next(this.list);
        }),
        catchError((error) => {
          this.initList();
          this.listSubject.error(error);
          return throwError(() => error);
        }),
        finalize(() => {
          this.isLoadingSubject.next(false);
        })
      )
      .subscribe();
  }

  /**
   * 조건에 해당하는 페이지를 요청한다
   */
  getPage(query = this.recentSearchQuery): void {
    this.isLoadingSubject.next(true);

    this.findPage(query)
      .pipe(
        tap((res) => {
          // 성공했다면 최근 조건으로 set
          this.recentSearchQuery = query;

          this.list.content = res.content;
          this.list.page = res.page;

          this.listSubject.next(this.list);
        }),
        catchError((error) => {
          this.initList();
          this.listSubject.error(error);
          return throwError(() => error);
        }),
        finalize(() => {
          this.isLoadingSubject.next(false);
        })
      )
      .subscribe();
  }

  getOptions(forceRefresh = true, params = {}): void {
    const reqParams = { ...params, size: 1000 };
    if (!this.options || this.options?.length < 1 || forceRefresh) {
      this.http
        .get<IHalPageResponse>(`${this.apiServerUrl}/${this.baseUri}`, {
          params: reqParams,
        })
        .pipe(
          tap((res) => {
            this.options = this.parsePage(res).content;
          }),
          catchError((error: HttpErrorResponse) => {
            delete this.options;
            this.optionsSubject.error(error);
            return throwError(() => error);
          }),
          finalize(() => {
            this.optionsSubject.next(this.options);
          })
        )
        .subscribe();
    }
  }

  /**
   * 현재 목록 초기화
   *
   * 최초 사용, 요청 오류 시 등에 사용
   */
  initList(): void {
    this.list = {
      content: [],
      page: null,
    };

    this.listSubject.next(this.list);
  }

  /**
   * 페이지를 요청하고, 현재 목록을 갱신한다
   *
   * @deprecated getPage, appendPage 로 검색 조건 포함하게 하고, 최근 기록이 네트워크 요청 후 설정되게 변경
   */
  changePage(pageNum: number): void {
    this.isLoadingSubject.next(true);
    if (this.list && this.list.page.totalPages < pageNum) {
      throw new Error('페이지 값 초과');
    }

    const query = {};

    Object.assign(query, this.searchQuery, { page: pageNum });

    this.findPage(query).subscribe({
      next: (res) => {
        this.list.content = res.content;
        this.list.page = res.page;

        this.listSubject.next(this.list);
      },
      error: (error) => {
        this.initList();
        this.listSubject.error(error);
      },
    });
  }

  /**
   * 서버에서 응답받은 페이지 형식을 클라이언트 처리 용이하게 변경
   */
  protected parsePage(serverResponse: IHalPageResponse): IPage<T> {
    const { _embedded, page, content } = serverResponse;

    return {
      content:
        (content || (_embedded && _embedded[Object.keys(_embedded)[0]])) ?? [],
      page,
    };
  }

  /**
   * 통신 오류 처리
   * 401 제외 4XX 통신 오류는 3번 재시도
   */
  protected handleError<R>(retryCount = 3): MonoTypeOperatorFunction<R> {
    let count = 0;
    return retryWhen((e) => {
      return e.pipe(
        mergeMap((v) => {
          if (v.status >= 400 && v.status <= 499) {
            if (count < retryCount - 1) {
              count += 1;
              return of(v);
            }
          }
          return throwError(() => new Error(this.getErrorMessages(v)));
        })
      );
    });
  }

  /**
   * 내트워크 오류 메시지 가공
   */
  private getErrorMessages({ status, error }: HttpErrorResponse): string {
    if (error?.errors) {
      const e: {
        code: string;
        errors: Record<string, string>[];
        message: string;
      } = error;
      let message = `${e.message}[${e.code}]`;
      e.errors.forEach((err) => {
        Object.entries(err).forEach(([key, value]) => {
          message += `\n${key} : ${value}`;
        });
      });
      return message;
    }

    if (status === 400 && error?.content[0]?.code) {
      // api 서버에서 badRequest 응답을 받았을 때. 예로 현 golftour 프로젝트에서 ExceptionHandler 없이 error 를 응답하는 경우 등
      return error?.content[0]?.code;
    }

    if (status === 500 && error?.message) {
      return error?.message;
    }

    return `Network Error(${status})`;
  }
}
