import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FindPwRoutingModule } from './find-pw-routing.module';
import { FindPwComponent } from './find-pw.component';

@NgModule({
  declarations: [FindPwComponent],
  imports: [
    CommonModule,
    FindPwRoutingModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [FindPwComponent],
})
export class FindPwModule {}
