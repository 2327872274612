import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateHtml',
})
export class TranslateHtmlPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private ref: ChangeDetectorRef,
    private dom: DomSanitizer
  ) {}

  transform(value: string, ...args: any[]): SafeHtml {
    return this.dom.bypassSecurityTrustHtml(
      new TranslatePipe(this.translateService, this.ref).transform(value, args)
    );
  }
}
