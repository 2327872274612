<!-- <main wrapper> -->
<div class="login-form">
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="title">
    {{ '로그인' | translate }}
  </h2>

  <div fxLayout="column" fxLayoutGap="16px" [formGroup]="formGroup">
    <input
      formControlName="userId"
      [placeholder]="'ID' | translate"
      (keyup)="onIdKeyup($event, pwInput)"
    />

    <input
      #pwInput
      type="password"
      formControlName="userPw"
      [placeholder]="'PW' | translate"
      (keyup)="onPwKeyup($event)"
    />

    <div fxLayout fxLayoutAlign="end center">
      <!-- <mat-checkbox color="primary">{{
        '로그인 상태 유지' | translate
      }}</mat-checkbox> -->
      <span class="find" (click)="onFindClick()">{{
        '회원정보 찾기' | translate
      }}</span>
    </div>

    <button class="login" (click)="onLoginClick()">LOGIN</button>
  </div>
</div>
<!-- </main> -->
