import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { mergeMap, tap } from 'rxjs';
import { UserInfoService } from 'src/app/repository/user-info.service';
import { DialogService } from '../dialog/dialog.service';

export const matchValidator = (matchTo: string): ValidatorFn | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    return control?.parent?.get(matchTo)?.value === control?.value
      ? null
      : { match: true };
  };
};

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  formGroup = this.formBuilder.group({
    userId: [null, [Validators.required, Validators.email]],
    userPw: [
      null,
      [Validators.required, Validators.minLength(8), Validators.maxLength(20)],
    ],
    _userPwMatch: [null, [Validators.required, matchValidator('userPw')]],
    // email: [
    //   null,
    //   Validators.email,
    //   // Validators.pattern(
    //   //   // eslint-disable-next-line no-useless-escape
    //   //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //   // ),
    // ],
    // _studentFamilyName: [null],
    // _studentFirstName: [null],
    // _studentFamilyNameFurigana: [null],
    // _studentFirstNameFurigana: [null],
    // birthday: [null],
    // _parentFamilyName: [null],
    // _parentFirstName: [null],
    // _parentFamilyNameFurigana: [null],
    // _parentFirstNameFurigana: [null],
    // zipcode: [null],
    // address: [null],
    // telNumber: [null],
    acceptAll: false,
    acceptTerms: false,
    acceptPrivacy: false,
  });

  constructor(
    public dialogRef: MatDialogRef<RegisterComponent>,
    private router: Router,
    private formBuilder: FormBuilder,
    private userInfoService: UserInfoService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    const acceptAllControl = this.formGroup.get('acceptAll');
    const acceptTermsControl = this.formGroup.get('acceptTerms');
    const acceptPrivacyControl = this.formGroup.get('acceptPrivacy');
    acceptAllControl.valueChanges.subscribe((value) => {
      acceptTermsControl.setValue(value);
      acceptPrivacyControl.setValue(value);
    });
    this.formGroup.valueChanges.subscribe(({ acceptTerms, acceptPrivacy }) => {
      acceptAllControl.setValue(
        acceptTerms === acceptPrivacy && acceptTerms && acceptPrivacy,
        {
          emitEvent: false,
        }
      );
    });
  }

  onSubmitClick(): void {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      console.log(this.formGroup);

      return;
    }

    const {
      userId,
      userPw,
      // email,
      // _studentFamilyName,
      // _studentFirstName,
      // _studentFamilyNameFurigana,
      // _studentFirstNameFurigana,
      // birthday,
      // _parentFamilyName,
      // _parentFirstName,
      // _parentFamilyNameFurigana,
      // _parentFirstNameFurigana,
      // zipcode,
      // address,
      // telNumber,
    } = this.formGroup.value;

    // const userNm = `${_studentFamilyName} ${_studentFirstName}`;
    // const nickNm = `${_studentFamilyNameFurigana} ${_studentFirstNameFurigana}`;
    // const parentUserNm = `${_parentFamilyName} ${_parentFirstName}`;
    // const parentNickNm = `${_parentFamilyNameFurigana} ${_parentFirstNameFurigana}`;
    // const remarksObj = { parentUserNm, parentNickNm };
    // const remarks = JSON.stringify(remarksObj);

    const params = {
      userId,
      userPw,
      email: userId,
      // email,
      // birthday,
      // zipcode,
      // address,
      // telNumber,
      // userNm,
      // nickNm,
      // remarks,
    };

    this.userInfoService
      .create(params)
      .pipe(
        mergeMap(() => {
          return this.dialogService.alert('登録されました。');
        }),
        tap(() => {
          this.dialogRef.close();
          // this.router.navigateByUrl('/home');
        })
      )
      .subscribe();
  }
}
