import { Injectable } from '@angular/core';

export type Menu = {
  name: string;
  basePath: string;
  subMenu: { name: string; path: string }[];
  backgroundUrl?: string;
  hide?: boolean;
  class?: string;
};

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  readonly MENU_LIST: Menu[] = [
    {
      name: '한인 교류회 소개',
      basePath: '/intro',
      subMenu: [
        {
          name: '한인교류회',
          path: '/intro/home',
        },
        {
          name: 'kumamotoHeadOffice',
          path: '/intro/mindan',
        },
        // TODO: 추후 추가
        // {
        //   name: '한인',
        //   path: '/intro/hanin',
        // },
        // {
        //   name: '재일교포',
        //   path: '/intro/kyopo',
        // },
      ],
    },
    {
      name: '활동/행사',
      basePath: '/event',
      subMenu: [
        {
          name: '행사 안내',
          path: '/event/intro',
        },
        {
          name: '연간 행사',
          path: '/event/annual',
        },
        {
          name: '행사 참가 신청',
          path: '/event/20240401',
        },
      ],
    },
    {
      name: '한국의 역사',
      basePath: '/history',
      subMenu: [
        // TODO: 추후 추가
        // {
        //   name: '한국의 역사',
        //   path: '/history/home',
        // },
        {
          name: '명성황후',
          path: '/history/myeongseong',
        },
      ],
    },
    {
      name: '한인 지원사업',
      basePath: '/support',
      subMenu: [
        // TODO: 추후 추가
        // {
        //   name: '관광 상품',
        //   path: '/support/tour',
        // },
        {
          name: '회사홍보지원',
          path: '/support/company',
        },
        {
          name: '골프용품판매',
          // path: '/support/food',
          path: '/shop',
        },
      ],
    },
    {
      name: '교육 사업',
      basePath: '/edu',
      subMenu: [
        {
          name: '한국어 강좌',
          path: '/edu/korean',
        },
        {
          name: '어린이 강좌',
          path: '/edu/children',
        },
        {
          name: '한식 강좌',
          path: '/edu/kfood',
        },
        {
          name: '한국문화',
          path: '/edu/kculture',
        },
      ],
    },
    {
      name: '한인교류회 뉴스',
      basePath: '/news',
      class: 'header-menu-news',
      subMenu: [
        {
          name: '공지사항',
          path: '/news/notice',
        },
        {
          name: '쿠마모토 소식',
          path: '/news/kumamoto',
        },
        {
          name: '포토갤러리',
          path: '/news/photo',
        },
      ],
    },
    {
      name: '마이페이지',
      // backgroundUrl: '/assets/imgs/sub_layout_02.jpg',
      basePath: '/my',
      subMenu: [
        {
          name: '내 정보',
          path: '/my/account',
        },
        {
          name: '장바구니',
          path: '/my/cart',
        },
        {
          name: '주문내역',
          path: '/my/ordr-hist',
        },
        {
          name: '예약내역',
          path: '/my/rsrv-hist',
        },
      ],
      hide: true,
    },
    {
      name: '쇼핑몰',
      basePath: '/shop',
      hide: true,
      subMenu: [],
    },
    // {
    //   name: '후쿠오카소개',
    //   basePath: '/intro',
    //   subMenu: [
    //     {
    //       path: '/intro/greet',
    //       name: '인사의 말씀',
    //     },
    //     {
    //       path: '/intro/guide',
    //       name: 'OKTA 안내',
    //     },
    //     {
    //       path: '/intro/history',
    //       name: '연혁',
    //     },
    //     {
    //       path: '/intro/map',
    //       name: '찾아오시는길',
    //     },
    //   ],
    // },
    // {
    //   name: '후쿠오카조직',
    //   basePath: '/org',
    //   subMenu: [
    //     {
    //       path: '/org/chart',
    //       name: '조직도',
    //     },
    //     {
    //       path: '/org/member',
    //       name: '명예회장',
    //     },
    //   ],
    // },
    // {
    //   name: '사업안내',
    //   basePath: '/biz',
    //   subMenu: [
    //     {
    //       path: '/biz/trade-school',
    //       name: '무역스쿨',
    //     },
    //     {
    //       path: '/biz/localize',
    //       name: '지사화 사업',
    //     },
    //     {
    //       path: '/biz/friend',
    //       name: '수출친구맺기',
    //     },
    //     {
    //       path: '/biz/recruit',
    //       name: '입회안내',
    //     },
    //   ],
    // },
    // {
    //   name: '회원서비스',
    //   basePath: '/gbc',
    //   subMenu: [
    //     {
    //       path: '/gbc/service',
    //       name: '회원서비스',
    //     },
    //     {
    //       path: '/gbc/benefit',
    //       name: '회원혜택',
    //     },
    //   ],
    // },
    // {
    //   name: '갤러리',
    //   basePath: '/gallery',
    //   subMenu: [
    //     {
    //       path: '/gallery/photo',
    //       name: '포토갤러리',
    //     },
    //     {
    //       path: '/gallery/video',
    //       name: '홍보동영상',
    //     },
    //   ],
    // },
    // {
    //   name: '회사상품소개',
    //   basePath: '/member-company',
    //   subMenu: [
    //     {
    //       path: '/member-company',
    //       name: '회사상품소개',
    //     },
    //   ],
    // },
    // {
    //   name: '커뮤니티',
    //   basePath: '/community',
    //   subMenu: [
    //     {
    //       path: '/community/notice',
    //       name: '공지사항 및 행사',
    //     },
    //     {
    //       path: '/community/news',
    //       name: '후쿠오카 뉴스',
    //     },
    //   ],
    // },
  ];

  constructor() {}
}
