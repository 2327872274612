import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { CertService } from '../repository/cert.service';

@Injectable({
  providedIn: 'root',
})
export class CertGuard implements CanActivate {
  constructor(private router: Router, private certService: CertService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { cert } = route.params;
    return this.certService.checkCertEncrypted(cert).pipe(
      map((res) => {
        return res.result || this.router.parseUrl('/');
      })
    );
  }
}
