import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PwLocaleModule } from 'projects/pw-lib/src/public-api';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { KumahaninFooterModule } from './kumahanin-footer/kumahanin-footer.module';
import { KumahaninHeaderModule } from './kumahanin-header/kumahanin-header.module';
import { KumahaninLayoutModule } from './kumahanin-layout/kumahanin-layout.module';
import { KumahaninSubLayoutModule } from './kumahanin-sub-layout/kumahanin-sub-layout.module';

const modules = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatSnackBarModule,
  MatRippleModule,
  KumahaninLayoutModule,
  KumahaninHeaderModule,
  KumahaninFooterModule,
  KumahaninSubLayoutModule,
  TranslateModule,
  PwLocaleModule,
  PipesModule,
];

/**
 * 공통 모듈
 */
@NgModule({
  declarations: [],
  imports: modules,
  exports: modules,
})
export class SharedModule {}
