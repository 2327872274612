<!-- <main wrapper fxLayout="column" fxLayoutAlign="center center"> -->
<div class="register-form">
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>

  <div class="header">
    <span>{{ '회원가입' | translate }}</span>
  </div>

  <div fxLayout="column" fxLayoutGap="20px" [formGroup]="formGroup">
    <!-- <mat-form-field appearance="outline">
      <mat-label>test</mat-label>
      <input matInput placeholder="test" />
    </mat-form-field> -->
    <input
      [placeholder]="'이메일' | translate"
      type="email"
      autocomplete="email"
      formControlName="userId"
    />

    <ng-container
      *ngIf="
        formGroup.controls.userId.touched &&
        formGroup.controls.userId.errors as errors
      "
    >
      <label class="error-messgae" *ngIf="errors?.required">
        {{ 'required' | translate }}
      </label>

      <label class="error-messgae" *ngIf="errors?.email">
        {{ 'eamilRegex' | translate }}
      </label>
    </ng-container>

    <input
      [placeholder]="'비밀번호' | translate"
      type="password"
      autocomplete="new-password"
      formControlName="userPw"
    />

    <ng-container *ngIf="formGroup.controls.userPw.errors as errors">
      <label class="error-messgae" *ngIf="errors?.required">
        {{ 'required' | translate }}
      </label>

      <label class="error-messgae" *ngIf="errors?.minlength">
        {{ 'minlength' | translate }}
      </label>

      <label class="error-messgae" *ngIf="errors?.maxlength">
        {{ 'maxlength' | translate }}
      </label>
    </ng-container>

    <input
      [placeholder]="'비밀번호 확인' | translate"
      type="password"
      autocomplete="new-password"
      formControlName="_userPwMatch"
    />

    <ng-container *ngIf="formGroup.controls._userPwMatch.errors as errors">
      <label class="error-messgae" *ngIf="errors?.match">
        {{ 'passwordNotMatch' | translate }}
      </label>
    </ng-container>

    <!-- <input placeholder="이름" autocomplete="name" />

    <div class="addr-search">
      <input placeholder="주소" />
      <button><img src="/assets/imgs/ic_search_light.svg" /></button>
    </div> -->
  </div>
  <ng-container [formGroup]="formGroup">
    <div class="accept-all" fxLayout fxLayoutAlign="start">
      <mat-checkbox formControlName="acceptAll" color="primary">{{
        '전체동의' | translate
      }}</mat-checkbox>
    </div>
    <div class="accept">
      <mat-checkbox formControlName="acceptTerms" color="primary">
        {{ '쿠마모토' | translate }} {{ '한인교류회' | translate }}
        {{ '이용약관 동의' | translate }}
        <span class="required">({{ '필수' | translate }})</span>
      </mat-checkbox>
      <textarea readonly>
      제1조 목적

      본 이용약관은
      </textarea>
    </div>
    <div class="accept">
      <mat-checkbox formControlName="acceptPrivacy" color="primary">
        {{ '개인정보 수집 및 이용 동의' | translate }}
        <span class="required">({{ '필수' | translate }})</span>
      </mat-checkbox>
      <textarea readonly>
      제1조 목적

      본 이용약관은
      </textarea>
    </div>
  </ng-container>

  <!-- <div class="accept">
    <mat-checkbox color="primary">
      {{ '개인정보 제공 동의' | translate }}
      <span class="required">({{ '필수' | translate }})</span>
    </mat-checkbox>
    <textarea readonly>
    제1조 목적

    본 이용약관은
    </textarea>
  </div> -->
  <!-- <div class="accept">
    <mat-checkbox color="primary">
      SNS 이벤트등 마케팅 수신 동의
      <span class="optional">(선택)</span>
    </mat-checkbox>
  </div>
  <div class="accept">
    <mat-checkbox color="primary">
      이메일 이벤트등 마케팅 수신 동의
      <span class="optional">(선택)</span>
    </mat-checkbox>
  </div>
  <div class="accept desc">
    <span class="optional"
      >※선택항목은 동의하지 않아도 회원가입이 가능합니다.</span
    >
  </div> -->
  <div class="accept-register">
    <button
      [disabled]="formGroup.invalid || !formGroup.value.acceptAll"
      (click)="onSubmitClick()"
    >
      {{ '동의하고 회원가입' | translate }}
    </button>
  </div>
  <!-- </main> -->
</div>
