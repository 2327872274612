import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { KumahaninFooterModule } from '../kumahanin-footer/kumahanin-footer.module';
import { KumahaninHeaderModule } from '../kumahanin-header/kumahanin-header.module';
import { KumahaninLayoutComponent } from './kumahanin-layout.component';

@NgModule({
  declarations: [KumahaninLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    KumahaninHeaderModule,
    KumahaninFooterModule,
    MatSidenavModule,
    MatExpansionModule,
    MatIconModule,
    MatRippleModule,
    FlexLayoutModule,
    TranslateModule,
  ],
})
export class KumahaninLayoutModule {}
