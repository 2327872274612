import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, tap, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DialogService } from '../dialog/dialog.service';
import { FindPwComponent } from '../find-pw/find-pw.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formGroup = this.formBuilder.group({
    userId: [null, Validators.required],
    userPw: [null, Validators.required],
  });

  constructor(
    private matDialogRef: MatDialogRef<LoginComponent>,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  onIdKeyup(event: KeyboardEvent, pwInput: HTMLInputElement): void {
    if (event.key === 'Enter') {
      pwInput.focus();
    }
  }

  onPwKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onLoginClick();
    }
  }

  onLoginClick(): void {
    if (this.formGroup.invalid) {
      this.dialogService.alert('idAndPwRequired').subscribe();
      return;
    }

    const { userId, userPw } = this.formGroup.value;

    this.authService
      .login(userId, userPw)
      .pipe(
        tap(() => {
          this.matDialogRef.close(true);
        }),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse?.error?.error === 'invalid_grant') {
            return this.dialogService.alert('incorrectIdOrPassword');
          }

          return throwError(() => httpErrorResponse);
        })
      )
      .subscribe();
  }

  onJoinClick(): void {
    //
  }

  onFindClick(): void {
    this.dialogService.matDialog.open(FindPwComponent, {
      data: {
        email: this.formGroup.value.userId,
      },
    });
  }
}
