<div class="find-form" *ngIf="dialogRef">
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
  <h2 class="title">{{ '회원정보 찾기' | translate }}</h2>
  <div class="form">
    <form #formEl [formGroup]="form" (submit)="onRequestSubmit(formEl)">
      <input
        formControlName="email"
        required
        type="email"
        [placeholder]="'이메일' | translate"
      />
      <button class="login">SEND</button>
    </form>
  </div>
</div>
<div class="find-form" *ngIf="currentUser">
  <h2 class="title">{{ '회원정보 찾기' | translate }}</h2>
  <div class="form">
    <form #formEl [formGroup]="pwForm" (submit)="onPwChangeSubmit(formEl)">
      <input
        autocomplete="username"
        formControlName="email"
        required
        type="email"
        [placeholder]="'이메일' | translate"
      />
      <input
        formControlName="userPw"
        type="password"
        autocomplete="new-password"
        required
        minlength="6"
        maxlength="15"
        [placeholder]="'영문/숫자/특수문자 조합 6~15자' | translate"
      />
      <input
        formControlName="userPwConfirm"
        type="password"
        autocomplete="new-password"
        required
        minlength="6"
        maxlength="15"
        (input)="$any($event.target).setCustomValidity('')"
        [placeholder]="'영문/숫자/특수문자 조합 6~15자' | translate"
      />

      <button class="login">SEND</button>
    </form>
  </div>
</div>
