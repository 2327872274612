import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CertResolver } from './cert.resolver';
import { FindPwComponent } from './find-pw.component';

const routes: Routes = [
  {
    path: '',
    component: FindPwComponent,
    resolve: {
      cert: CertResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FindPwRoutingModule {}
