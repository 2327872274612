import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogService } from '../components/dialog/dialog.service';
import { LoginComponent } from '../components/login/login.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree>
    | UrlTree
    | boolean {
    const { path } = route.routeConfig;
    const returnValue =
      path === 'login' ? this.router.parseUrl('/my/account') : true;

    if (this.authService.loginInfo) {
      return returnValue;
    }

    return this.dialogService.matDialog
      .open(LoginComponent, {
        panelClass: 'fullscreen-modal',
        hasBackdrop: false,
      })
      .beforeClosed()
      .pipe(
        map((isLogin) => {
          return isLogin ? returnValue : false;
        })
      );
  }
}
