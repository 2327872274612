import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import { SplitPipe } from './split.pipe';
import { TranslateHtmlPipe } from './translate-html.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    SplitPipe,
    TranslateHtmlPipe,
  ],
  imports: [CommonModule],
  exports: [SafeHtmlPipe, SafeResourceUrlPipe, SplitPipe, TranslateHtmlPipe],
})
export class PipesModule {}
