import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginInfo } from 'projects/pw-lib/src/public-api';
import { mergeMap } from 'rxjs';
import { CertService } from '../../repository/cert.service';
import { UserInfoService } from '../../repository/user-info.service';
import { DialogService } from '../dialog/dialog.service';

export const matchValidator = (matchTo: string): ValidatorFn | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    return control?.parent?.get(matchTo)?.value === control?.value
      ? null
      : { match: true };
  };
};

@Component({
  selector: 'app-find-pw',
  templateUrl: './find-pw.component.html',
  styleUrls: ['./find-pw.component.scss'],
})
export class FindPwComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    email: [null, Validators.required],
  });

  pwForm: FormGroup = this.formBuilder.group({
    email: null,
    userPw: [
      null,
      [Validators.required, Validators.minLength(6), Validators.maxLength(15)],
    ],
    userPwConfirm: [
      null,
      [
        Validators.required,
        matchValidator('userPw'),
        Validators.minLength(6),
        Validators.maxLength(15),
      ],
    ],
  });

  currentUser: LoginInfo;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    @Optional()
    public dialogRef: MatDialogRef<FindPwComponent>,
    private dialogService: DialogService,
    private certService: CertService,
    private userService: UserInfoService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.data?.email) {
      this.form.patchValue({ email: this.data.email });
    }
    if (this.activatedRoute.snapshot.data.cert) {
      this.userService
        .findPage({
          userId: this.activatedRoute.snapshot.data.cert.receiver,
        })
        .subscribe((res) => {
          if (res.content && res.content.length === 1) {
            [this.currentUser] = res.content;
            this.pwForm.patchValue({ email: this.currentUser.email });
            this.pwForm.controls.email.disable();
          } else {
            this.dialogService
              .alert('가입하지 않은 이메일입니다.')
              .subscribe(() => {
                this.router.navigate(['/']);
              });
          }
        });
    }
  }

  onSendUrl(): void {
    this.certService
      .requestCert('email', {
        brandCd: '0',
        corpNo: '0',
        certUrlHost: `${window.location.origin}/find-pw/`,
        reqType: 'URL',
        receiver: this.form.value.email,
      })
      .subscribe(() => {
        this.dialogService
          .alert(
            '초기화 이메일이 전송되었습니다.\n이메일을 확인하여 다음 단계를 진행하세요.'
          )
          .subscribe(() => {
            this.dialogRef.close();
          });
      });
  }

  onRequestSubmit(formEl: HTMLFormElement): void {
    if (formEl.reportValidity()) {
      this.onSendUrl();
    }
  }

  onPwChangeSubmit(formEl: HTMLFormElement): void {
    const list = formEl.getElementsByTagName('input');
    const confirmEl = list.item(list.length - 1);
    confirmEl.setCustomValidity('');
    if (
      !this.pwForm.controls.userPwConfirm.errors?.required &&
      !this.pwForm.controls.userPwConfirm.errors?.minlength &&
      !this.pwForm.controls.userPwConfirm.errors?.maxlength &&
      this.pwForm.controls.userPwConfirm.errors?.match
    ) {
      confirmEl.setCustomValidity(
        this.translateService.instant('비밀번호가 일치하지 않습니다.')
      );
    }
    if (formEl.reportValidity()) {
      this.userService
        .updatePw(this.currentUser.id, this.pwForm.value)
        .pipe(
          mergeMap(() => this.dialogService.alert('비밀번호가 변경되었습니다.'))
        )
        .subscribe(() => this.router.navigate(['/']));
    }
  }
}
