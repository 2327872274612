/* eslint-disable camelcase */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractAuthService, LoginInfo, StorageService } from 'pw-lib';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../components/dialog/dialog.service';

/**
 * 인증(토큰) 정보 취급 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractAuthService {
  loginSubject$ = new BehaviorSubject(this.loginInfo);

  constructor(
    protected httpClient: HttpClient,
    protected storageService: StorageService,
    private router: Router,
    private dialogService: DialogService
  ) {
    super(
      httpClient,
      storageService,
      `${environment.apiServerUrl}/oauth/token`,
      'hmpg',
      'asoyamanami:paywith1234'
    );
  }

  /**
   * 로그인 정보 취득
   */
  getLoginInfo$(): Observable<LoginInfo> {
    if (this.loginInfo) {
      // 클래스 내의 loginInfo 값이 있으면, info 호출
      return of(this.loginInfo);
    }

    // 인증정보 획득
    return this.getNewAuth().pipe(
      mergeMap(() => {
        // 로그인 정보 획득
        return this.httpClient.get(`${environment.apiServerUrl}/api/userInfo`, {
          params: { userId: this.userName },
        });
      }),
      map((mrhstTrmnlList: any) => {
        if (mrhstTrmnlList?.content?.length < 1) {
          throw new Error('Check ID or Password please.');
        }

        this.loginInfo = { ...mrhstTrmnlList?.content[0], userPw: undefined };
        this.loginSubject$.next(this.loginInfo);

        // 로그인 정보 반환
        return this.loginInfo;
      })
    );
  }

  refreshLoginInfo$(): Observable<LoginInfo> {
    this.userName = this.loginInfo?.userId;
    return this.httpClient
      .get(`${environment.apiServerUrl}/api/userInfo`, {
        params: { userId: this.userName },
      })
      .pipe(
        map((userInfoList: any) => {
          this.loginInfo = { ...userInfoList?.content[0], userPw: undefined };
          this.loginSubject$.next(this.loginInfo);

          // 로그인 정보 반환
          return this.loginInfo;
        })
      );
  }

  /**
   * 로그아웃
   */
  logout(): void {
    this.clearAuth();
    this.loginSubject$.next(null);
    this.router.navigateByUrl('/login');
  }
}
