import { Overlay } from '@angular/cdk/overlay';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Menu, MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-kumahanin-sub-layout',
  templateUrl: './kumahanin-sub-layout.component.html',
  styleUrls: ['./kumahanin-sub-layout.component.scss'],
})
export class KumahaninSubLayoutComponent implements OnInit {
  menuList: any[] = [];

  isMenuOpen = false;

  @Input() hideLayoutTitle = false;

  constructor(
    public router: Router,
    public overlay: Overlay,
    private menu: MenuService
  ) {}

  ngOnInit(): void {
    this.menuList = this.getMenuList();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuList = this.getMenuList();
      });
  }

  getBaseMenu(): Menu {
    const basePath = `/${this.router.url.split('/')[1]}`;
    return this.menu.MENU_LIST.find((menu) => {
      return menu.basePath === basePath;
    });
  }

  getMenuList(): any[] {
    return this.getBaseMenu()?.subMenu ?? [];
  }

  getMenuPaths(): string[] {
    return [this.getLayoutTitle(), this.getContentTitle()];
  }

  getLayoutTitle(): string {
    return this.getBaseMenu()?.name ?? '';
  }

  getContentTitle(): string {
    return this.getMenuList().find((menu) => {
      return this.router.url.includes(menu.path);
    })?.name;
  }

  getMenuBackgroundUrl(): string {
    let path = '/assets/imgs/sub_layout.jpeg';
    const { backgroundUrl } = this.getBaseMenu();
    if (backgroundUrl) {
      path = backgroundUrl;
    }
    return `url(${path})`;
  }
}
