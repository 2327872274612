import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';

export class AppMissingTranslationHandler extends MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    // return `T${params.key}${params.translateService.currentLang}T`;
    return params.key;
  }
}
