<section
  class="sub-header"
  fxLayout="column"
  fxLayoutAlign="center center"
  ngClass.lt-sm="small"
  [ngStyle]="{ 'background-image': getMenuBackgroundUrl() }"
>
  <h2>{{ getLayoutTitle() | translate }}</h2>
  <span class="desc">{{ '한민족 네트워크의 중심' | translate }}</span>
</section>
<section class="nav" ngClass.lt-sm="small">
  <div
    fxHide.lt-sm
    class="wrapper"
    fxLayout
    fxLayoutAlign="end center"
    fxLayoutGap="6px"
  >
    <a routerLink="/home">Home</a>
    <span>></span>
    <ng-container *ngFor="let path of getMenuPaths(); last as last">
      <span>{{ path | translate }}</span>
      <span *ngIf="!last">></span>
    </ng-container>
  </div>
  <div fxHide fxShow.lt-sm class="wrapper" fxLayout>
    <mat-icon mat-ripple routerLink="/home">home</mat-icon>
    <button
      #trigger="cdkOverlayOrigin"
      class="menu"
      cdkOverlayOrigin
      (click)="isMenuOpen = !isMenuOpen"
    >
      <span>{{ getLayoutTitle() | translate }}</span>
    </button>
    <!-- TODO: 하단 요소 이벤트 발생 막기 -->
    <ng-template
      #menu
      cdkConnectedOverlay
      [cdkConnectedOverlayScrollStrategy]="overlay.scrollStrategies.block()"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isMenuOpen"
      [cdkConnectedOverlayPositions]="[
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: -1
        }
      ]"
    >
      <menu
        fxHide
        fxShow.lt-sm
        class="menu-list"
        (click)="$event.stopImmediatePropagation()"
      >
        <li
          *ngFor="let menu of menuList"
          mat-ripple
          [routerLink]="menu.path"
          routerLinkActive="on"
        >
          <a>{{ menu.name | translate }}</a>
        </li>
      </menu>
    </ng-template>
  </div>
</section>
<section class="content-section">
  <div class="wrapper" fxLayout fxLayoutGap="50px">
    <aside fxHide.lt-sm>
      <h2>
        {{ getLayoutTitle() | translate }}
      </h2>
      <menu>
        <li
          *ngFor="let menu of menuList"
          [routerLink]="menu.path"
          routerLinkActive="on"
        >
          <a>{{ menu.name | translate }}</a>
        </li>
      </menu>
    </aside>
    <div fxFlex class="content" ngClass.lt-sm="small">
      <h2 *ngIf="!hideLayoutTitle">{{ getContentTitle() | translate }}</h2>
      <ng-content></ng-content>
    </div>
  </div>
</section>
