import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kumahanin-footer',
  templateUrl: './kumahanin-footer.component.html',
  styleUrls: ['./kumahanin-footer.component.scss'],
})
export class KumahaninFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
