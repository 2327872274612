import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { KumahaninSubLayoutComponent } from './kumahanin-sub-layout.component';

@NgModule({
  declarations: [KumahaninSubLayoutComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    OverlayModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [KumahaninSubLayoutComponent],
})
export class KumahaninSubLayoutModule {}
