import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { KumahaninFooterComponent } from './kumahanin-footer.component';

@NgModule({
  declarations: [KumahaninFooterComponent],
  imports: [CommonModule, RouterModule, FlexLayoutModule],
  exports: [KumahaninFooterComponent],
})
export class KumahaninFooterModule {}
