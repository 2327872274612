import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CertService } from '../../repository/cert.service';
import { CertResponse } from '../../repository/cert/cert-response.model';

@Injectable({
  providedIn: 'root',
})
export class CertResolver implements Resolve<CertResponse> {
  constructor(private certService: CertService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CertResponse> {
    const { cert } = route.params;
    return this.certService.checkCertEncrypted(cert);
  }
}
