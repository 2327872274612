<div
  fxLayout
  safe-bottom
  safe-side
  class="footer-wrapper"
  fxLayout
  fxLayoutAlign="start center"
  fxLayoutGap="6.5%"
  ngClass.lt-sm="small"
>
  <div fxHide.lt-sm>
    쿠마모토 한인교류회 로고
    <!-- <img src="/assets/imgs/okta-logo.png" /> -->
  </div>
  <div>
    <!-- <ul fxLayout fxLayoutGap="30px" fxLayoutGap.lt-sm="10px">
      <li><a routerLink="/intro/map">찾아오시는길</a></li>
    </ul>
    <br fxHide.lt-sm /> -->
    <span>
      〒 000-0000 주소 <br />
      Tel.000-000-0000 / Fax.000-000-0000 / E-mail.cwi@paywith.co.kr<br />
      Copyright ⓒ Paywith All Right Reserved.
    </span>
  </div>
</div>
